<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-row>
          <b-col>
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro.org_name')"
              label-for="organiation_id"
            >
              <b-form-select
                plain
                v-model="search.org_id"
                :options="organizationList"
                id="organiation_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irrigation_config.type_name')"
              label-for="payment"
            >
              <b-form-input
                id="payment"
                v-model="search.type_name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irrigation_config.payment') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(amount)="data">
                      {{ $n(data.item.amount) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-edit variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="testId" :key="testId"/>
      </p>
    </b-modal>

    <b-modal id="modal-edit" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <EditForm :id="testId" :key="testId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import FormV from './Form'
import EditForm from './EditForm'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { paymentList, paymentToggleStatus } from '../../api/routes'

export default {
  name: 'UiDataTable',
  components: {
    FormV, EditForm
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 0
      },
      search: {
        org_id: 0,
        type_name: ''
      },
      testId: 0,
      rows: [],
      organizationList: []
    }
  },
  computed: {
    formTitle () {
       return (this.testId === 0) ? this.$t('irrigation_config.payment_entry') : this.$t('irrigation_config.payment') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.IrriConfig.paymentTypes
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('irrigation_config.type_name'), class: 'text-left' },
          { label: this.$t('irrigation_config.amount'), class: 'text-left' },
          { label: this.$t('irrigation_config.organization'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'type_name_bn' },
          { key: 'amount' },
          { key: 'org_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'type_name' },
          { key: 'amount' },
          { key: 'org_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
    this.getOrganizationlist()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        type_name: '',
        type_name_bn: '',
        org_id: '',
        amount: ''
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.testId = item.id
    },
    toggleStatus (item) {
      RestApi.deleteData(irriSchemeServiceBaseUrl, `${paymentToggleStatus}/${item.id}`).then(response => {
        if (response.success) {
          this.$store.dispatch('IrriConfig/togglePaymentTypeStatus', item)
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.update_msg'),
            color: '#D6E09B'
          })
        } else {}
      })
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(irriSchemeServiceBaseUrl, paymentList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('IrriConfig/addPaymentTypeList', this.dataList(response.data.data))
          this.paginationData(response.data)
        }
      })
      //
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    dataList (data) {
      const orgList = this.$store.state.orgList
      let tmpData = {}
      const listData = data.map(item => {
        tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
        const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
        return Object.assign({}, item, orgData)
      })
      return listData
    },
    getOrganizationlist () {
      this.organizationList = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  }
}
</script>
