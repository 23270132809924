<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <ValidationProvider name="Organiation Name" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro.org_name')"
                        label-for="organiation_id"
                        slot-scope="{ valid, errors }"
                        >
                      <template v-slot:label>
                        {{$t('org_pro.org_name')}} <span class="text-danger">*</span>
                      </template>
                        <b-form-select
                          plain
                          v-model="payment.org_id"
                          :options="organizationList"
                          id="organiation_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                      <table>
                        <th>
                          <td>Fee Name</td>
                          <td>Amount</td>
                        </th>
                      </table>
                    <ValidationProvider name="Amount" vid="amount" v-for="(item, index) in paymentTypeList" :key="index">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="item.text"
                        label-for="amount"
                        slot-scope="{ valid, errors }"
                      >
                          <!-- <input :id="item.text.toString()" type="hidden" v-model="payment.type_id">
                          <input :id="item.text.toString()" type="hidden" v-model="payment.type_name[item.text]">
                          <input :id="item.text.toString()" type="hidden" v-model="payment.type_name_bn"> -->
                          <b-form-input
                          type="number"
                          :id="item.text.toString()"
                          v-model="payment.amount[item.value]"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <!-- <ValidationProvider name="Type name (bn)" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('irrigation_config.type_name_bn')"
                        label-for="type_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <b-form-input
                          id="type_name_bn"
                          v-model="payment.type_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Amount" vid="amount" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('irrigation_config.amount')"
                        label-for="amount"
                        slot-scope="{ valid, errors }"
                      >
                        <b-form-input
                          type="number"
                          id="amount"
                          v-model="payment.amount"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider> -->
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { paymentStore, paymentUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getPaymentData()
      Object.freeze(tmp)
      this.payment = tmp
    }
    this.getOrganizationlist()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      payment: {
        org_id: 0,
        type_id: [],
        type_name: [],
        type_name_bn: [],
        amount: []
      },
      organizationList: []
    }
  },
  computed: {
    paymentTypeList: function () {
      const listObject = this.$store.state.commonObj.paymentTypeList
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
      return tmpList
    }
    // organizationList: function () {
    //   const listObject = this.$store.state.OrgProfile.organizations
    //   const tmpList = listObject.map((obj, index) => {
    //     if (this.$i18n.locale === 'bn') {
    //       return { value: obj.id, text: obj.org_name_bn }
    //     } else {
    //       return { value: obj.id, text: obj.org_name }
    //     }
    //   })
    //   return tmpList
    // }
  },
  watch: {

  },
  methods: {
    getPaymentData () {
      return this.$store.state.IrriConfig.paymentTypes.find(item => item.id === this.id)
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }

      // this.payment.type_id = this.paymentTypeList.type_id
      // this.payment.type_name = this.paymentTypeList.type_name
      // this.payment.type_name_bn = this.paymentTypeList.type_name_bn
      this.getFormatedFormValue()

      if (this.payment.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${paymentUpdate}/${this.id}`, this.payment)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, paymentStore, this.payment)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data Update successfully' : 'Data saved successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getFormatedFormValue () {
      const listObject = this.$store.state.commonObj.paymentTypeList
      const paymentAmounts = this.payment.amount
      this.payment.type_id = []
      this.payment.type_name = []
      this.payment.type_name_bn = []
      paymentAmounts.forEach((item, id) => {
        const typeItem = listObject.find(tmpItem => tmpItem.id === id)
        this.payment.type_id.push(typeItem.id)
        this.payment.type_name.push(typeItem.name)
        this.payment.type_name_bn.push(typeItem.name_bn)
      })
    },
    getOrganizationlist () {
      this.organizationList = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  }
}
</script>
